/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleClientId } from './utils/constants';
import { ConnectedRouter } from 'connected-react-router';
import 'regenerator-runtime/runtime';

// Import root app
import App from 'containers/App/Loadable';
import SentryInit from './utils/sentry';
import openReplayTracker from './OpenReplay';

// Import Language Provider
// import LanguageProvider from 'containers/LanguageProvider';

// Load the .htaccess file
// eslint-disable-next-line import/no-unresolved
import 'file-loader?name=[name]!./.htaccess';

import ActionConst from 'actions/const';
import api from 'api';
import { getStore, getHistory } from './store';

// Import i18n messages

SentryInit();
const history = getHistory();
const store = getStore();
const MOUNT_NODE = document.getElementById('app');
openReplayTracker.start();

// remove any existing service workers
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

const render = () => {
  store.dispatch({ type: ActionConst.LOAD_SESSION });

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <App />
        </GoogleOAuthProvider>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

// Chunked polyfill for browsers without Intl support
(async () => {
  if (!Intl) {
    await import(/* webpackChunkName: "polyfill" */ 'intl');
    await import(
      /* webpackChunkName: "polyfill" */ 'intl/locale-data/jsonp/en.js'
    );
  }
  await Promise.all([
    ...(!Intl.PluralRules
      ? [
          await import(
            /* webpackChunkName: "polyfill" */ '@formatjs/intl-pluralrules/polyfill'
          ),
          await import(
            /* webpackChunkName: "polyfill" */ '@formatjs/intl-pluralrules/dist/locale-data/en'
          ),
        ]
      : []),
    ...(!Intl.RelativeTimeFormat
      ? [
          import(
            /* webpackChunkName: "polyfill" */ '@formatjs/intl-relativetimeformat/polyfill'
          ),
          import(
            /* webpackChunkName: "polyfill" */ '@formatjs/intl-relativetimeformat/dist/locale-data/en'
          ),
        ]
      : []),
  ]);
  render();
})();

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
